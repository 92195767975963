/**
 * cuisine.digital - Cookie Banner
 */
export default class DC_Bakery {
  static cache = [];
  static cookie_setter_orig;
  static cookie_getter_orig;

  static __init = () => {
    if (typeof window !== 'undefined') {
      if (typeof document !== 'undefined') {
        DC_Bakery.cookie_setter_orig = document
          .__lookupSetter__('cookie')
          .bind(document);

        DC_Bakery.cookie_getter_orig = document
          .__lookupGetter__('cookie')
          .bind(document);

        return Object.defineProperty(document, 'cookie', {
          get: function () {
            if (DC_Bakery.cookie_getter_orig) {
              return DC_Bakery.cookie_getter_orig.apply(document);
            }
            return '';
          },
          set: (value) => DC_Bakery.cache.push(value),
          configurable: true,
        });
      }
      return DC_Bakery.__init();
    }
  };

  static clean() {
    Object.defineProperty(document, 'cookie', {
      set: function (value) {
        return DC_Bakery.cookie_setter_orig.apply(document, arguments);
      },
      get: function () {
        return DC_Bakery.cookie_getter_orig.apply(document);
      },
    });
  }

  static flush() {
    DC_Bakery.cache.forEach((cookie) => (document.cookie = cookie));
  }
}
